import { UniversalHeaderConfig, useFeature } from '@amzn/sitc-frontend/hooks';

import { universalHeaderConfig } from '../configs/universal-header';

export const useUniversalHeaderConfig = (overrides: Partial<UniversalHeaderConfig> = {}): UniversalHeaderConfig => {
  const { productIdentifieri18nKey, ...universalHeaderProps } = universalHeaderConfig;
  const enableSearchInHeader = useFeature('enableUniversalHeaderSearch');
  return {
    originSearch: {
      enable: enableSearchInHeader,
      getSearchResultNavigatePath(searchResult) {
        return `/titles/${searchResult.vertexId}`;
      },
    },
    ...universalHeaderProps,
    ...overrides,
  };
};
